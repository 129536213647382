/* Theme Description
-------------------------------------------------

	  Theme Name:
	  Author:
	  Support:
	  Description:
	  Version:
	  
------------------------------------------------- 
*/
/* CSS Index
-------------------------------------------------

	1. Theme default css
	2. header
	3. slider
	4. about-area
	5. features-box
	6. department
	7. team
	8. video-area
	9. counter
	10. footer

-------------------------------------------------
*/
/*-------------   Color Variable  --------------*/
@import url("https://fonts.googleapis.com/css?family=Alex+Brush|Roboto:300,400,700,900|Titillium+Web:300,400,600,700&display=swap");
/* line 34, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.white-bg {
  background: #ffffff;
}

/* line 37, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.gray-bg {
  background: #f5f5f5;
}

/*-------------Color include--------------*/
/*-- Background color---*/
/* line 74, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.section-bg1 {
  background: #13151b;
}

/* line 78, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.gray-bg {
  background: #f7f7fd;
}

/* line 81, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.white-bg {
  background: #ffffff;
}

/* line 84, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.black-bg {
  background: #16161a;
}

/* line 87, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.theme-bg {
  background: #14435c;
}

/* line 90, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.brand-bg {
  background: #f1f4fa;
}

/* line 93, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.testimonial-bg {
  background: #f9fafc;
}

/*--- color------*/
/* line 99, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.white-color {
  color: #ffffff;
}

/* line 102, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.black-color {
  color: #16161a;
}

/* line 105, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.theme-color {
  color: #14435c;
}

/*------------------- Responsive --------------------------*/
/*
	@include transition(.4s);
	color:$theme-color;
*/
/* line 58, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_variables.scss */
.boxed-btn {
  background: #fff;
  color: #14435c !important;
  display: inline-block;
  padding: 18px 44px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  border: 0;
  border: 1px solid #14435c;
  letter-spacing: 3px;
  text-align: center;
  color: #14435c;
  text-transform: uppercase;
  cursor: pointer;
}

/* line 75, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_variables.scss */
.boxed-btn:hover {
  background: #14435c;
  color: #fff !important;
  border: 1px solid #14435c;
}

/* line 80, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_variables.scss */
.boxed-btn:focus {
  outline: none;
}

/* line 83, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_variables.scss */
.boxed-btn.large-width {
  width: 220px;
}

/*--
    - Overlay
------------------------------------------*/
/* line 4, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_overlay.scss */
[data-overlay] {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/* line 9, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_overlay.scss */
[data-overlay]::before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
}

/*-- Overlay Opacity --*/
/* line 22, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_overlay.scss */
[data-opacity="1"]::before {
  opacity: 0.1;
}

/* line 27, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_overlay.scss */
[data-opacity="2"]::before {
  opacity: 0.2;
}

/* line 32, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_overlay.scss */
[data-opacity="3"]::before {
  opacity: 0.3;
}

/* line 37, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_overlay.scss */
[data-opacity="4"]::before {
  opacity: 0.4;
}

/* line 42, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_overlay.scss */
[data-opacity="5"]::before {
  opacity: 0.5;
}

/* line 47, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_overlay.scss */
[data-opacity="6"]::before {
  opacity: 0.6;
}

/* line 52, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_overlay.scss */
[data-opacity="7"]::before {
  opacity: 0.7;
}

/* line 57, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_overlay.scss */
[data-opacity="8"]::before {
  opacity: 0.8;
}

/* line 62, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_overlay.scss */
[data-opacity="9"]::before {
  opacity: 0.9;
}

/* 1. Theme default css */
/* line 4, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
body {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
}

/* line 9, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  color: #092c3f;
  margin-top: 0px;
  font-style: normal;
  font-weight: 500;
  text-transform: normal;
}

/* line 23, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
p {
  font-family: "Poppins", sans-serif;
  color: #10285d;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: normal;
}

/* line 34, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.single-brand.slick-slide {
  outline: 0;
}

/* line 37, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.slick-initialized .slick-slide {
  outline: 0;
}

/* line 42, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.bg-img-1 {
  /* background-image: url(../img/slider/slider-img-1.jpg); */
}

/* line 45, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.bg-img-2 {
  /* background-image: url(../img/background-img/bg-img-2.jpg); */
}

/* line 48, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.cta-bg-1 {
  /* background-image: url(../img/background-img/bg-img-3.jpg); */
}

/* line 53, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.img {
  max-width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/* line 57, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.f-left {
  float: left;
}

/* line 60, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.f-right {
  float: right;
}

/* line 63, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.fix {
  overflow: hidden;
}

/* line 66, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.clear {
  clear: both;
}

/* line 70, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
a,
.button {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/* line 74, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

/* line 79, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
a {
  color: #635c5c;
}

/* line 82, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
a:hover {
  color: #fff;
}

/* line 85, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
a:focus,
a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
  text-decoration: none;
}

/* line 91, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
a,
button {
  color: #fff;
  outline: medium none;
}

/* line 96, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}

/* line 97, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.uppercase {
  text-transform: uppercase;
}

/* line 101, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
input:focus::-moz-placeholder {
  opacity: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

/* line 108, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.capitalize {
  text-transform: capitalize;
}

/* line 112, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

/* line 121, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
ul {
  margin: 0px;
  padding: 0px;
}

/* line 125, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
li {
  list-style: none;
}

/* line 129, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
hr {
  border-bottom: 1px solid #eceff8;
  border-top: 0 none;
  margin: 30px 0;
  padding: 0;
}

/* Theme-overlay */
/* line 137, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.theme-overlay {
  position: relative;
}

/* line 140, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.theme-overlay::before {
  background: #1696e7 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}

/* line 150, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.overlay {
  position: relative;
  z-index: 0;
}

/* line 154, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.overlay::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* line 164, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.overlay2 {
  position: relative;
  z-index: 0;
}

/* line 168, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.overlay2::before {
  position: absolute;
  content: "";
  background-color: #2e2200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5;
}

/* line 181, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.section-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

/* line 185, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.separator {
  border-top: 1px solid #f2f2f2;
}

/* line 189, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-90 {
  margin-bottom: 90px;
}

@media (max-width: 767px) {
  /* line 189, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .mb-90 {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 189, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .mb-90 {
    margin-bottom: 45px;
  }
}

/* owl-carousel button style */
/* line 201, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.owl-carousel .owl-nav div {
  background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
  height: 40px;
  left: 20px;
  line-height: 40px;
  font-size: 22px;
  color: #646464;
  opacity: 1;
  visibility: visible;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  width: 40px;
}

/* line 220, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.owl-carousel .owl-nav div.owl-next {
  left: auto;
  right: -30px;
}

/* line 223, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.owl-carousel .owl-nav div.owl-next i {
  position: relative;
  right: 0;
  top: 1px;
}

/* line 230, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.owl-carousel .owl-nav div.owl-prev i {
  position: relative;
  right: 1px;
  top: 0px;
}

/* line 240, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.owl-carousel:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

/* line 243, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.owl-carousel:hover .owl-nav div:hover {
  color: #fff;
  background: #ff3500;
}

/* line 253, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.btn {
  background: #ff2143;
  padding: 27px 44px;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14 px;
  border-radius: 0px;
  -moz-user-select: none;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 0;
  margin-bottom: 0;
  margin: 10px;
  cursor: pointer;
  transition: color 0.4s linear;
  position: relative;
  z-index: 1;
  border: 0;
  overflow: hidden;
  margin: 0;
}

/* line 279, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 101%;
  height: 101%;
  background: #fff;
  color: #000 !important;
  z-index: 1;
  transition: transform 0.5s;
  transition-timing-function: ease;
  transform-origin: 0 0;
  transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
  transform: scaleX(0);
  border-radius: 0;
}

/* line 298, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.btn:hover::before {
  transform: scaleX(1);
  color: #000000 !important;
  z-index: -1;
}

/* line 303, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
a:hover {
  color: #000;
}

/* line 306, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

/* line 310, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.header-btn {
  padding: 30px 53px;
}

/* line 313, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.btn-black {
  background: #0b1416;
}

/* line 316, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.btn3 {
  width: 100%;
  padding: 59px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 316, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .btn3 {
    margin-top: 30px;
    padding: 35px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 316, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .btn3 {
    padding: 35px 0;
  }
}

@media (max-width: 575px) {
  /* line 316, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .btn3 {
    padding: 35px 0;
  }
}

/* line 330, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.submit-btn {
  width: 100%;
  background: #ff2143;
  padding: 19px 44px;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  border-radius: 5px;
  border: 0;
}

/* line 342, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.hero-btn {
  padding: 30px 38px;
}

/* line 345, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
hero-btn {
  padding: 30px 38px;
}

@media (max-width: 575px) {
  /* line 348, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .btn.btn-black.f-right {
    float: left;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 348, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .btn.btn-black.f-right {
    float: left;
    margin-top: 20px;
  }
}

/* line 360, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.submit-btn2 {
  background: #ec5b53;
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  border: 0;
  color: #fff;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 16px;
  border-radius: 0px;
}

/* line 373, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.border-btn {
  border: 1px solid #e3c6c6;
  color: #464d65;
  background: none;
  border-radius: 5px;
  text-transform: capitalize;
  padding: 25px 57px;
  -moz-user-select: none;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 0;
  position: relative;
  transition: color 0.4s linear;
  position: relative;
  overflow: hidden;
  margin: 0;
}

@media (max-width: 575px) {
  /* line 373, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .border-btn {
    display: none;
  }
}

/* line 397, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.border-btn::before {
  border: 1px solid transparent;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 101%;
  height: 101%;
  background: #14435c;
  z-index: -1;
  transition: transform 0.5s;
  transition-timing-function: ease;
  transform-origin: 0 0;
  transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
  transform: scaleY(0);
  border-radius: 0;
}

/* line 416, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.border-btn:hover::before {
  transform: scaleY(1);
  border: 1px solid transparent;
}

/* button style */
/* line 427, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.breadcrumb > .active {
  color: #888;
}

/* scrollUp */
/* line 431, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
#scrollUp,
#back-top {
  background: #14435c;
  height: 50px;
  width: 50px;
  right: 31px;
  bottom: 18px;
  position: fixed;
  z-index: 10;
  color: #fff;
  font-size: 20px;
  text-align: center;
  border-radius: 50%;
  line-height: 48px;
  border: 2px solid transparent;
  box-shadow: 0 0 10px 3px rgba(108, 98, 98, 0.2);
}

@media (max-width: 575px) {
  /* line 431, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  #scrollUp,
  #back-top {
    right: 16px;
  }
}

/* line 449, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
#scrollUp a i,
#back-top a i {
  display: block;
  line-height: 50px;
}

/* line 456, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
#scrollUp:hover {
  color: #fff;
}

/* Sticky*/
/* line 461, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.sticky-bar {
  left: 0;
  margin: auto;
  position: fixed;
  top: 0;
  width: 100%;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  z-index: 9999;
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  background: #fff;
}

/* Sticky end */
/*--- Margin & Padding --*/
/*-- Margin Top --*/
/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-5 {
  margin-top: 5px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-10 {
  margin-top: 10px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-15 {
  margin-top: 15px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-20 {
  margin-top: 20px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-25 {
  margin-top: 25px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-30 {
  margin-top: 30px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-35 {
  margin-top: 35px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-40 {
  margin-top: 40px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-45 {
  margin-top: 45px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-50 {
  margin-top: 50px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-55 {
  margin-top: 55px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-60 {
  margin-top: 60px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-65 {
  margin-top: 65px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-70 {
  margin-top: 70px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-75 {
  margin-top: 75px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-80 {
  margin-top: 80px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-85 {
  margin-top: 85px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-90 {
  margin-top: 90px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-95 {
  margin-top: 95px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-100 {
  margin-top: 100px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-105 {
  margin-top: 105px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-110 {
  margin-top: 110px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-115 {
  margin-top: 115px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-120 {
  margin-top: 120px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-125 {
  margin-top: 125px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-130 {
  margin-top: 130px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-135 {
  margin-top: 135px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-140 {
  margin-top: 140px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-145 {
  margin-top: 145px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-150 {
  margin-top: 150px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-155 {
  margin-top: 155px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-160 {
  margin-top: 160px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-165 {
  margin-top: 165px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-170 {
  margin-top: 170px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-175 {
  margin-top: 175px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-180 {
  margin-top: 180px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-185 {
  margin-top: 185px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-190 {
  margin-top: 190px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-195 {
  margin-top: 195px;
}

/* line 486, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mt-200 {
  margin-top: 200px;
}

/*-- Margin Bottom --*/
/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-5 {
  margin-bottom: 5px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-10 {
  margin-bottom: 10px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-15 {
  margin-bottom: 15px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-20 {
  margin-bottom: 20px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-25 {
  margin-bottom: 25px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-30 {
  margin-bottom: 30px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-35 {
  margin-bottom: 35px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-40 {
  margin-bottom: 40px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-45 {
  margin-bottom: 45px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-50 {
  margin-bottom: 50px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-55 {
  margin-bottom: 55px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-60 {
  margin-bottom: 60px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-65 {
  margin-bottom: 65px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-70 {
  margin-bottom: 70px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-75 {
  margin-bottom: 75px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-80 {
  margin-bottom: 80px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-85 {
  margin-bottom: 85px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-90 {
  margin-bottom: 90px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-95 {
  margin-bottom: 95px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-100 {
  margin-bottom: 100px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-105 {
  margin-bottom: 105px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-110 {
  margin-bottom: 110px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-115 {
  margin-bottom: 115px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-120 {
  margin-bottom: 120px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-125 {
  margin-bottom: 125px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-130 {
  margin-bottom: 130px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-135 {
  margin-bottom: 135px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-140 {
  margin-bottom: 140px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-145 {
  margin-bottom: 145px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-150 {
  margin-bottom: 150px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-155 {
  margin-bottom: 155px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-160 {
  margin-bottom: 160px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-165 {
  margin-bottom: 165px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-170 {
  margin-bottom: 170px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-175 {
  margin-bottom: 175px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-180 {
  margin-bottom: 180px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-185 {
  margin-bottom: 185px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-190 {
  margin-bottom: 190px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-195 {
  margin-bottom: 195px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mb-200 {
  margin-bottom: 200px;
}

/*-- Margin Left --*/
/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-5 {
  margin-left: 5px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-10 {
  margin-left: 10px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-15 {
  margin-left: 15px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-20 {
  margin-left: 20px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-25 {
  margin-left: 25px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-30 {
  margin-left: 30px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-35 {
  margin-left: 35px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-40 {
  margin-left: 40px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-45 {
  margin-left: 45px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-50 {
  margin-left: 50px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-55 {
  margin-left: 55px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-60 {
  margin-left: 60px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-65 {
  margin-left: 65px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-70 {
  margin-left: 70px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-75 {
  margin-left: 75px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-80 {
  margin-left: 80px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-85 {
  margin-left: 85px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-90 {
  margin-left: 90px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-95 {
  margin-left: 95px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-100 {
  margin-left: 100px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-105 {
  margin-left: 105px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-110 {
  margin-left: 110px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-115 {
  margin-left: 115px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-120 {
  margin-left: 120px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-125 {
  margin-left: 125px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-130 {
  margin-left: 130px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-135 {
  margin-left: 135px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-140 {
  margin-left: 140px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-145 {
  margin-left: 145px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-150 {
  margin-left: 150px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-155 {
  margin-left: 155px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-160 {
  margin-left: 160px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-165 {
  margin-left: 165px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-170 {
  margin-left: 170px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-175 {
  margin-left: 175px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-180 {
  margin-left: 180px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-185 {
  margin-left: 185px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-190 {
  margin-left: 190px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-195 {
  margin-left: 195px;
}

/* line 495, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.ml-200 {
  margin-left: 200px;
}

/*-- Margin Right --*/
/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-5 {
  margin-right: 5px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-10 {
  margin-right: 10px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-15 {
  margin-right: 15px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-20 {
  margin-right: 20px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-25 {
  margin-right: 25px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-30 {
  margin-right: 30px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-35 {
  margin-right: 35px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-40 {
  margin-right: 40px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-45 {
  margin-right: 45px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-50 {
  margin-right: 50px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-55 {
  margin-right: 55px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-60 {
  margin-right: 60px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-65 {
  margin-right: 65px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-70 {
  margin-right: 70px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-75 {
  margin-right: 75px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-80 {
  margin-right: 80px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-85 {
  margin-right: 85px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-90 {
  margin-right: 90px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-95 {
  margin-right: 95px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-100 {
  margin-right: 100px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-105 {
  margin-right: 105px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-110 {
  margin-right: 110px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-115 {
  margin-right: 115px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-120 {
  margin-right: 120px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-125 {
  margin-right: 125px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-130 {
  margin-right: 130px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-135 {
  margin-right: 135px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-140 {
  margin-right: 140px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-145 {
  margin-right: 145px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-150 {
  margin-right: 150px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-155 {
  margin-right: 155px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-160 {
  margin-right: 160px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-165 {
  margin-right: 165px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-170 {
  margin-right: 170px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-175 {
  margin-right: 175px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-180 {
  margin-right: 180px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-185 {
  margin-right: 185px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-190 {
  margin-right: 190px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-195 {
  margin-right: 195px;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.mr-200 {
  margin-right: 200px;
}

/*-- Padding Top --*/
/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-5 {
  padding-top: 5px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-10 {
  padding-top: 10px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-15 {
  padding-top: 15px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-20 {
  padding-top: 20px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-25 {
  padding-top: 25px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-30 {
  padding-top: 30px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-35 {
  padding-top: 35px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-40 {
  padding-top: 40px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-45 {
  padding-top: 45px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-50 {
  padding-top: 50px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-55 {
  padding-top: 55px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-60 {
  padding-top: 60px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-65 {
  padding-top: 65px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-70 {
  padding-top: 70px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-75 {
  padding-top: 75px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-80 {
  padding-top: 80px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-85 {
  padding-top: 85px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-90 {
  padding-top: 90px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-95 {
  padding-top: 95px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-100 {
  padding-top: 100px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-105 {
  padding-top: 105px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-110 {
  padding-top: 110px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-115 {
  padding-top: 115px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-120 {
  padding-top: 120px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-125 {
  padding-top: 125px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-130 {
  padding-top: 130px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-135 {
  padding-top: 135px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-140 {
  padding-top: 140px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-145 {
  padding-top: 145px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-150 {
  padding-top: 150px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-155 {
  padding-top: 155px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-160 {
  padding-top: 160px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-165 {
  padding-top: 165px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-170 {
  padding-top: 170px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-175 {
  padding-top: 175px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-180 {
  padding-top: 180px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-185 {
  padding-top: 185px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-190 {
  padding-top: 190px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-195 {
  padding-top: 195px;
}

/* line 505, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-200 {
  padding-top: 200px;
}

/* line 507, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pt-260 {
  padding-top: 260px;
}

/*-- Padding Bottom --*/
/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-5 {
  padding-bottom: 5px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-10 {
  padding-bottom: 10px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-15 {
  padding-bottom: 15px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-20 {
  padding-bottom: 20px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-25 {
  padding-bottom: 25px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-30 {
  padding-bottom: 30px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-35 {
  padding-bottom: 35px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-40 {
  padding-bottom: 40px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-45 {
  padding-bottom: 45px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-50 {
  padding-bottom: 50px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-55 {
  padding-bottom: 55px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-60 {
  padding-bottom: 60px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-65 {
  padding-bottom: 65px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-70 {
  padding-bottom: 70px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-75 {
  padding-bottom: 75px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-80 {
  padding-bottom: 80px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-85 {
  padding-bottom: 85px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-90 {
  padding-bottom: 90px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-95 {
  padding-bottom: 95px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-100 {
  padding-bottom: 100px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-105 {
  padding-bottom: 105px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-110 {
  padding-bottom: 110px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-115 {
  padding-bottom: 115px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-120 {
  padding-bottom: 120px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-125 {
  padding-bottom: 125px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-130 {
  padding-bottom: 130px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-135 {
  padding-bottom: 135px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-140 {
  padding-bottom: 140px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-145 {
  padding-bottom: 145px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-150 {
  padding-bottom: 150px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-155 {
  padding-bottom: 155px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-160 {
  padding-bottom: 160px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-165 {
  padding-bottom: 165px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-170 {
  padding-bottom: 170px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-175 {
  padding-bottom: 175px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-180 {
  padding-bottom: 180px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-185 {
  padding-bottom: 185px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-190 {
  padding-bottom: 190px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-195 {
  padding-bottom: 195px;
}

/* line 512, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-200 {
  padding-bottom: 200px;
}

/*-- Padding Left --*/
/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-5 {
  padding-left: 5px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-10 {
  padding-left: 10px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-15 {
  padding-left: 15px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-20 {
  padding-left: 20px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-25 {
  padding-left: 25px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-30 {
  padding-left: 30px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-35 {
  padding-left: 35px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-40 {
  padding-left: 40px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-45 {
  padding-left: 45px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-50 {
  padding-left: 50px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-55 {
  padding-left: 55px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-60 {
  padding-left: 60px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-65 {
  padding-left: 65px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-70 {
  padding-left: 70px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-75 {
  padding-left: 75px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-80 {
  padding-left: 80px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-85 {
  padding-left: 85px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-90 {
  padding-left: 90px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-95 {
  padding-left: 95px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-100 {
  padding-left: 100px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-105 {
  padding-left: 105px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-110 {
  padding-left: 110px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-115 {
  padding-left: 115px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-120 {
  padding-left: 120px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-125 {
  padding-left: 125px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-130 {
  padding-left: 130px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-135 {
  padding-left: 135px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-140 {
  padding-left: 140px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-145 {
  padding-left: 145px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-150 {
  padding-left: 150px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-155 {
  padding-left: 155px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-160 {
  padding-left: 160px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-165 {
  padding-left: 165px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-170 {
  padding-left: 170px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-175 {
  padding-left: 175px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-180 {
  padding-left: 180px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-185 {
  padding-left: 185px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-190 {
  padding-left: 190px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-195 {
  padding-left: 195px;
}

/* line 517, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pl-200 {
  padding-left: 200px;
}

/*-- Padding Right --*/
/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-5 {
  padding-right: 5px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-10 {
  padding-right: 10px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-15 {
  padding-right: 15px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-20 {
  padding-right: 20px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-25 {
  padding-right: 25px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-30 {
  padding-right: 30px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-35 {
  padding-right: 35px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-40 {
  padding-right: 40px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-45 {
  padding-right: 45px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-50 {
  padding-right: 50px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-55 {
  padding-right: 55px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-60 {
  padding-right: 60px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-65 {
  padding-right: 65px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-70 {
  padding-right: 70px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-75 {
  padding-right: 75px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-80 {
  padding-right: 80px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-85 {
  padding-right: 85px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-90 {
  padding-right: 90px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-95 {
  padding-right: 95px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-100 {
  padding-right: 100px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-105 {
  padding-right: 105px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-110 {
  padding-right: 110px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-115 {
  padding-right: 115px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-120 {
  padding-right: 120px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-125 {
  padding-right: 125px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-130 {
  padding-right: 130px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-135 {
  padding-right: 135px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-140 {
  padding-right: 140px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-145 {
  padding-right: 145px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-150 {
  padding-right: 150px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-155 {
  padding-right: 155px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-160 {
  padding-right: 160px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-165 {
  padding-right: 165px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-170 {
  padding-right: 170px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-175 {
  padding-right: 175px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-180 {
  padding-right: 180px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-185 {
  padding-right: 185px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-190 {
  padding-right: 190px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-195 {
  padding-right: 195px;
}

/* line 522, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pr-200 {
  padding-right: 200px;
}

/*-- Some Animated CSS -- */
/* bounce-animate */
/* line 533, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.bounce-animate {
  animation-name: float-bob;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  /* animation-timing-function: linear; */
  -moz-animation-name: float-bob;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@-webkit-keyframes float-bob {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

/* heartbeat */
/* line 563, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.heartbeat {
  animation: heartbeat 1s infinite alternate;
}

@-webkit-keyframes heartbeat {
  to {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
}

/* rotateme */
/* line 575, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

/* 20. preloader */
/* line 607, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.preloader {
  background-color: #f7f7f7;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  margin: 0 auto;
}

/* line 623, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.preloader .preloader-circle {
  width: 100px;
  height: 100px;
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-top-color: #14435c;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 10;
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
  box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
  background-color: #fff;
  -webkit-animation: zoom 2000ms infinite ease;
  animation: zoom 2000ms infinite ease;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

/* line 645, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.preloader .preloader-circle2 {
  border-top-color: #0078ff;
}

/* line 648, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.preloader .preloader-img {
  position: absolute;
  top: 50%;
  z-index: 200;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-top: 6px;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

/* line 666, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.preloader .preloader-img img {
  max-width: 55px;
}

/* line 669, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.preloader .pere-text strong {
  font-weight: 800;
  color: #dca73a;
  text-transform: uppercase;
}

@-webkit-keyframes zoom {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
}

@keyframes zoom {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
}

/*-- Section Padding -- */
/* line 713, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.section-padding2 {
  padding-top: 200px;
  padding-bottom: 200px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  /* line 713, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-padding2 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 713, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-padding2 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 713, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-padding2 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 713, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-padding2 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (max-width: 575px) {
  /* line 713, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-padding2 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

/* line 738, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-bottom {
  padding-bottom: 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 738, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .pb-bottom {
    padding-bottom: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 738, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .pb-bottom {
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 738, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .pb-bottom {
    padding-bottom: 10px;
  }
}

@media (max-width: 575px) {
  /* line 738, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .pb-bottom {
    padding-bottom: 10px;
  }
}

/* line 753, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.pb-top {
  padding-top: 200px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 753, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .pb-top {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 753, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .pb-top {
    padding-top: 70px;
  }
}

@media (max-width: 575px) {
  /* line 753, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .pb-top {
    padding-top: 70px;
  }
}

/* line 765, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.testimonial-padding {
  padding-top: 148px;
  padding-bottom: 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 765, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .testimonial-padding {
    padding-top: 148px;
    padding-bottom: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 765, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .testimonial-padding {
    padding-top: 148px;
    padding-bottom: 200px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 765, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .testimonial-padding {
    padding-top: 148px;
    padding-bottom: 200px;
  }
}

@media (max-width: 575px) {
  /* line 765, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .testimonial-padding {
    padding-top: 148px;
    padding-bottom: 200px;
  }
}

/* line 785, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.w-padding2 {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 785, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .w-padding2 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (max-width: 575px) {
  /* line 785, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .w-padding2 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

/* line 797, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.section-padding30 {
  padding-top: 195px;
  padding-bottom: 170px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  /* line 797, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-padding30 {
    padding-top: 195px;
    padding-bottom: 170px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 797, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-padding30 {
    padding-top: 150px;
    padding-bottom: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 797, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-padding30 {
    padding-top: 100px;
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 797, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-padding30 {
    padding-top: 65px;
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  /* line 797, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-padding30 {
    padding-top: 65px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 822, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .brand-area {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (max-width: 575px) {
  /* line 822, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .brand-area {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* line 832, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.padding-bottom {
  padding-bottom: 190px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 832, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .padding-bottom {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 832, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .padding-bottom {
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 832, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .padding-bottom {
    padding-bottom: 70px;
  }
}

@media (max-width: 575px) {
  /* line 832, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .padding-bottom {
    padding-bottom: 70px;
  }
}

/* line 847, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.services-padding {
  padding-top: 145px;
  padding-bottom: 170px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 847, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .services-padding {
    padding-top: 145px;
    padding-bottom: 170px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 847, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .services-padding {
    padding-top: 100px;
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 847, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .services-padding {
    padding-top: 65px;
    padding-bottom: 70px;
  }
}

@media (max-width: 575px) {
  /* line 847, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .services-padding {
    padding-top: 65px;
    padding-bottom: 70px;
  }
}

/* line 867, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.section-paddingt30 {
  padding-top: 195px;
  padding-bottom: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 867, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-paddingt30 {
    padding-top: 150px;
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 867, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-paddingt30 {
    padding-top: 100px;
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 867, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-paddingt30 {
    padding-top: 65px;
    padding-bottom: 10px;
  }
}

@media (max-width: 575px) {
  /* line 867, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-paddingt30 {
    padding-top: 65px;
    padding-bottom: 10px;
  }
}

/* line 889, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.section-padding3 {
  padding-top: 195px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 889, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-padding3 {
    padding-top: 195px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 889, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-padding3 {
    padding-top: 95px;
    padding-bottom: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 889, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-padding3 {
    padding-top: 60px;
    padding-bottom: 0;
  }
}

@media (max-width: 575px) {
  /* line 889, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-padding3 {
    padding-top: 60px;
    padding-bottom: 0;
  }
}

/* line 910, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.footer-padding {
  padding-top: 143px;
  padding-bottom: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 910, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .footer-padding {
    padding-top: 143px;
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 910, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .footer-padding {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 910, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .footer-padding {
    padding-top: 80px;
    padding-bottom: 0px;
  }
}

@media (max-width: 575px) {
  /* line 910, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .footer-padding {
    padding-top: 80px;
    padding-bottom: 0px;
  }
}

@media (max-width: 575px) {
  /* line 933, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-tittle {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 933, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-tittle {
    margin-bottom: 50px;
  }
}

/* line 940, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.section-tittle span {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin-bottom: 17px;
  text-transform: uppercase;
  color: #eb566c;
  display: inline-block;
  position: relative;
  padding-left: 68px;
}

@media (max-width: 575px) {
  /* line 940, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-tittle span {
    font-size: 14px;
  }
}

/* line 953, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.section-tittle span::before {
  position: absolute;
  content: "";
  width: 54px;
  height: 2px;
  background: #eb566c;
  top: 0;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}

/* line 965, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.section-tittle h2 {
  font-size: 50px;
  display: block;
  color: #092c3f;
  font-weight: 900;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 965, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-tittle h2 {
    font-size: 31px;
  }
}

@media (max-width: 575px) {
  /* line 965, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-tittle h2 {
    font-size: 31px;
  }
}

/* line 979, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.section-tittle2 span {
  color: #cecece;
}

/* line 981, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.section-tittle2 span::before {
  background: #fff;
}

/* line 985, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.section-tittle2 h2 {
  color: #fff;
}

@media (max-width: 575px) {
  /* line 985, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-tittle2 h2 {
    font-size: 27px;
  }
}

/* line 996, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.section-tittle3 span {
  margin-left: 72px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 999, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-tittle3 h2 {
    font-size: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 999, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-tittle3 h2 {
    margin-bottom: 50px;
    font-size: 45px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 999, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-tittle3 h2 {
    text-align: left;
    margin-bottom: 50px;
    font-size: 26px;
  }
}

@media (max-width: 575px) {
  /* line 999, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
  .section-tittle3 h2 {
    text-align: left;
    font-size: 26px;
    margin-bottom: 50px;
  }
}

/* line 1021, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_common.scss */
.section-bg {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

/*-------------   Color Variable  --------------*/
/* line 34, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.white-bg {
  background: #ffffff;
}

/* line 37, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.gray-bg {
  background: #f5f5f5;
}

/*-------------Color include--------------*/
/*-- Background color---*/
/* line 74, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.section-bg1 {
  background: #13151b;
}

/* line 78, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.gray-bg {
  background: #f7f7fd;
}

/* line 81, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.white-bg {
  background: #ffffff;
}

/* line 84, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.black-bg {
  background: #16161a;
}

/* line 87, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.theme-bg {
  background: #14435c;
}

/* line 90, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.brand-bg {
  background: #f1f4fa;
}

/* line 93, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.testimonial-bg {
  background: #f9fafc;
}

/*--- color------*/
/* line 99, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.white-color {
  color: #ffffff;
}

/* line 102, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.black-color {
  color: #16161a;
}

/* line 105, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_color.scss */
.theme-color {
  color: #14435c;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
  .header-area .header-bottom {
    padding: 17px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
  .header-area .header-bottom {
    padding: 17px 0px;
  }
}

@media (max-width: 575px) {
  /* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
  .header-area .header-bottom {
    padding: 17px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 19, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
  .header-area {
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 19, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
  .header-area {
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  }
}

@media (max-width: 575px) {
  /* line 19, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
  .header-area {
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  }
}

/* line 30, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.header-area .header-top {
  background: #0d2d3e;
  padding: 13px 0px;
}

/* line 36, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.header-area .header-top .header-info-left ul li {
  color: #fff;
  display: inline-block;
  margin-right: 30px;
  font-size: 12px;
  font-weight: 100;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

/* line 44, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.header-area .header-top .header-info-left ul li:last-child {
  margin-right: 0px;
}

/* line 47, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.header-area .header-top .header-info-left ul li i {
  margin-right: 8px;
  color: #e94d65;
}

/* line 56, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.header-area .header-top .header-info-right .header-social li {
  display: inline-block;
}

/* line 58, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.header-area .header-top .header-info-right .header-social li a {
  color: #fff;
  font-size: 16px;
  padding-left: 25px;
}

/* line 62, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.header-area .header-top .header-info-right .header-social li a:hover {
  color: #14435c;
}

/* line 73, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.main-header {
  position: relative;
  z-index: 3;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  /* line 76, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
  .main-header .main-menu {
    margin-right: 30px;
  }
}

/* line 81, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.main-header .main-menu ul li {
  display: inline-block;
  position: relative;
  z-index: 1;
}

/* line 85, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.main-header .main-menu ul li a {
  color: #0d2d3e;
  font-weight: 400;
  padding: 39px 19px;
  display: block;
  font-size: 16px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  text-transform: capitalize;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 85, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
  .main-header .main-menu ul li a {
    padding: 39px 14px;
  }
}

/* line 99, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.main-header .main-menu ul li:hover > a {
  color: #14435c;
}

/* line 104, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.main-header .main-menu ul li:last-child a {
  padding-right: 0;
}

/* line 109, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.main-header .main-menu ul ul.submenu {
  position: absolute;
  width: 170px;
  background: #0d2d3e;
  left: 0;
  top: 90%;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
  padding: 17px 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/* line 121, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.main-header .main-menu ul ul.submenu > li {
  margin-left: 7px;
  display: block;
}

/* line 124, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.main-header .main-menu ul ul.submenu > li > a {
  padding: 6px 10px !important;
  font-size: 15px;
  color: #fdfdfd;
}

/* line 128, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.main-header .main-menu ul ul.submenu > li > a:hover {
  background: none;
  padding-left: 13px !important;
}

/* line 143, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.main-header ul > li:hover > ul.submenu {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

/* line 149, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.header-transparent {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
}

/* line 163, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.header-area .header-top .header-info-right .header-social a i {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
}

/* line 172, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.header-area .header-top .header-info-right .header-social a:hover i {
  color: #ff2143;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
}

/* line 190, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.header-area .header-sticky.sticky-bar {
  background: #fff;
}

/* line 195, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.header-area .header-sticky.sticky-bar .main-menu nav > ul > li > a {
  padding: 25px 20px !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 190, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
  .header-area .header-sticky.sticky-bar {
    padding: 15px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 190, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
  .header-area .header-sticky.sticky-bar {
    padding: 15px 0px;
  }
}

@media (max-width: 575px) {
  /* line 190, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
  .header-area .header-sticky.sticky-bar {
    padding: 15px 0px;
  }
}

/* line 218, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.mobile_menu {
  position: absolute;
  right: 0px;
  width: 100%;
  z-index: 99;
}

/* line 223, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.mobile_menu .slicknav_menu {
  background: transparent;
  margin-top: 0px !important;
}

/* line 226, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.mobile_menu .slicknav_menu .slicknav_btn {
  top: -28px;
}

/* line 229, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.mobile_menu .slicknav_menu .slicknav_btn .slicknav_icon-bar {
  background-color: #14435c !important;
}

/* line 233, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.mobile_menu .slicknav_menu .slicknav_nav {
  margin-top: 10px !important;
}

/* line 236, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.mobile_menu .slicknav_menu .slicknav_nav a:hover {
  background: transparent;
  color: #14435c;
}

/* line 240, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.mobile_menu .slicknav_menu .slicknav_nav a {
  font-size: 15px;
  padding: 7px 10px;
}

/* line 245, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_headerMenu.scss */
.mobile_menu .slicknav_menu .slicknav_nav .slicknav_item a {
  padding: 0 !important;
}

/* line 1, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-height {
  height: 900px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 1, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-height {
    height: 700px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 1, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-height {
    height: 650px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 1, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-height {
    height: 500px;
  }
}

@media (max-width: 575px) {
  /* line 1, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-height {
    height: 500px;
  }
}

/* line 18, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-height2 {
  height: 450px;
  background-size: cover;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 18, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-height2 {
    height: 350px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 18, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-height2 {
    height: 260px;
  }
}

@media (max-width: 575px) {
  /* line 18, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-height2 {
    height: 260px;
  }
}

/* line 31, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-area2 {
  background-image: url(../img/hero/hero2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

/* line 37, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-area {
  margin-top: -1px;
  background-image: url(../img/hero/h1_hero.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

/* line 43, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-area .hero__caption {
  overflow: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  /* line 43, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-area .hero__caption {
    padding-left: 100px;
  }
}

/* line 48, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-area .hero__caption span {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin-bottom: 22px;
  text-transform: uppercase;
  color: #eb566c;
  display: inline-block;
  position: relative;
  padding-left: 100px;
}

@media (max-width: 575px) {
  /* line 48, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-area .hero__caption span {
    font-size: 13px;
  }
}

/* line 61, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-area .hero__caption span::before {
  position: absolute;
  content: "";
  width: 70px;
  height: 2px;
  background: #eb566c;
  top: 0;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}

/* line 73, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-area .hero__caption h1 {
  font-size: 85px;
  font-weight: 800;
  margin-bottom: 14px;
  color: #000000;
  font-family: "Sarabun", sans-serif;
  line-height: 1.2;
  margin-bottom: 33px;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  /* line 73, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-area .hero__caption h1 {
    font-size: 71px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 73, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-area .hero__caption h1 {
    font-size: 60px;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 73, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-area .hero__caption h1 {
    font-size: 50px;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 73, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-area .hero__caption h1 {
    font-size: 35px;
    line-height: 1.2;
  }
}

@media (max-width: 575px) {
  /* line 73, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-area .hero__caption h1 {
    font-size: 29px;
    line-height: 1.2;
  }
}

/* line 101, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-area .hero__caption p {
  font-size: 16px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  margin-bottom: 39px;
  margin-bottom: 7px;
  margin-bottom: 50px;
}

@media (max-width: 575px) {
  /* line 101, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-area .hero__caption p {
    margin-bottom: 30px;
    font-size: 14px;
  }
  /* line 111, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-area .hero__caption p br {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 116, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-area .hero__caption p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 121, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-area .hero__caption p br {
    display: none;
  }
}

/* line 130, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.hero-overly {
  position: relative;
  z-index: 1;
}

/* line 133, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.hero-overly::before {
  position: absolute;
  content: "";
  background-color: rgba(1, 10, 28, 0.3);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-repeat: no-repeat;
}

/* line 147, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.hero-overly2 {
  position: relative;
  z-index: 1;
}

/* line 150, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.hero-overly2::before {
  position: absolute;
  content: "";
  background-color: rgba(13, 45, 62, 0.8);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-repeat: no-repeat;
}

/* line 166, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.header-transparent {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
}

/* line 177, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-area2 .hero-cap h2 {
  color: #fff;
  font-size: 60px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 177, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-area2 .hero-cap h2 {
    font-size: 50px;
  }
}

@media (max-width: 575px) {
  /* line 177, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-area2 .hero-cap h2 {
    font-size: 35px;
  }
}

/* line 190, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-area2 .hero-cap .breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
}

/* line 195, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-area2 .hero-cap .breadcrumb a {
  color: #dcdcdc;
  font-size: 18px;
  text-transform: capitalize;
}

/* line 201, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-area2 .hero-cap .breadcrumb-item + .breadcrumb-item::before {
  color: #dcdcdc;
}

/* line 212, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-active button.slick-arrow {
  position: absolute;
  top: 50%;
  left: 100px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: none;
  border: 0;
  padding: 0;
  z-index: 2;
  opacity: 1;
  visibility: visible;
  height: 60px;
  width: 60px;
  cursor: pointer;
  border: 1px solid #a6b4bb;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  /* line 212, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-active button.slick-arrow {
    left: 15px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  /* line 212, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-active button.slick-arrow {
    left: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 212, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-active button.slick-arrow {
    right: 130px;
    left: auto;
  }
}

/* line 243, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-active button.slick-arrow i {
  font-size: 20px;
  line-height: 60px;
  color: #a6b4bb;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/* line 250, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-active button.slick-next {
  left: auto;
  right: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 250, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-active button.slick-next {
    right: 50px;
  }
}

/* line 258, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-active button.slick-next {
  border: 1px solid #1b4962;
}

/* line 260, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-active button.slick-next i {
  color: #1b4962;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  /* line 258, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
  .slider-active button.slick-next {
    right: 20px;
  }
}

/* line 267, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-active button:hover {
  border: 1px solid #1b4962;
}

/* line 269, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_h1-hero.scss */
.slider-active button:hover i {
  color: #1b4962;
}

/* line 1, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
.categories-area {
  background: #f7f7f7;
}

/* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
.categories-area .single-cat {
  border: 9px solid transparent;
  padding: 30px 22px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  position: relative;
  z-index: 1;
  background: #ffff;
}

@media (max-width: 575px) {
  /* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
  .categories-area .single-cat {
    padding: 30px 22px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
  .categories-area .single-cat {
    padding: 30px 18px;
  }
}

/* line 16, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
.categories-area .single-cat::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  background: #ff2143;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  bottom: 0;
  left: 0;
  z-index: -1;
  border: 9px solid #fff;
}

/* line 29, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
.categories-area .single-cat .cat-icon span {
  color: #eb566c;
  font-size: 65px;
  margin-bottom: 30px;
  display: block;
}

/* line 38, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
.categories-area .single-cat .cat-cap h5 > a {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 21px;
  display: block;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -ms-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
  font-family: "Sarabun", sans-serif;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 38, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
  .categories-area .single-cat .cat-cap h5 > a {
    font-size: 21px;
  }
}

@media (max-width: 575px) {
  /* line 38, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
  .categories-area .single-cat .cat-cap h5 > a {
    font-size: 23px;
  }
}

/* line 53, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
.categories-area .single-cat .cat-cap p {
  margin-bottom: 36px;
  color: #57667e;
  font-size: 16px;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -ms-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 53, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
  .categories-area .single-cat .cat-cap p {
    font-size: 15px;
  }
}

/* line 63, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
.categories-area .single-cat .cat-cap a {
  color: #1c165c;
  font-size: 16px;
  font-weight: 600;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -ms-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}

/* line 73, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
.categories-area .single-cat:hover {
  border: 1px solid transparent;
}

/* line 77, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
.categories-area .single-cat:hover::before {
  height: 100%;
}

/* line 81, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
.categories-area .single-cat:hover .cat-icon span {
  color: #fff;
}

/* line 86, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
.categories-area .single-cat:hover .cat-cap h5 {
  color: #fff;
}

/* line 89, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
.categories-area .single-cat:hover .cat-cap p {
  color: #fff;
}

/* line 92, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_categories.scss */
.categories-area .single-cat:hover .cat-cap a {
  color: #fff;
}

/* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_about.scss */
.support-company-area .support-location-img img {
  width: 100%;
}

/* line 8, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_about.scss */
.support-company-area .right-caption {
  position: relative;
  right: -50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 8, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_about.scss */
  .support-company-area .right-caption {
    right: -25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 8, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_about.scss */
  .support-company-area .right-caption {
    right: 0px;
    margin-bottom: 40px;
    padding-top: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 8, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_about.scss */
  .support-company-area .right-caption {
    right: 0px;
    margin-bottom: 40px;
    padding-top: 50px;
  }
}

@media (max-width: 575px) {
  /* line 8, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_about.scss */
  .support-company-area .right-caption {
    right: 0px;
    margin-bottom: 40px;
    padding-top: 50px;
  }
}

/* line 31, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_about.scss */
.support-company-area .right-caption .support-caption p.pera-top {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

/* line 37, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_about.scss */
.support-company-area .right-caption .support-caption p {
  font-size: 15px;
  color: #cecece;
}

/* line 1, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_count_down.scss */
.count-down-area {
  margin-top: -60px;
}

/* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_count_down.scss */
.count-down-area .single-counter {
  background: #fff;
  padding: 44px 10px 44px 9px;
  border: 1px solid #cfcfcf;
  margin-bottom: 30px;
}

/* line 8, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_count_down.scss */
.count-down-area .single-counter.active {
  background: #ff2143;
  border: 10px solid #fff;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  position: relative;
  border: 0;
}

/* line 14, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_count_down.scss */
.count-down-area .single-counter.active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 10px solid #fff;
  left: 0;
  top: 0;
}

/* line 23, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_count_down.scss */
.count-down-area .single-counter.active span {
  color: #fff;
}

/* line 26, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_count_down.scss */
.count-down-area .single-counter.active p {
  color: #fff;
}

/* line 31, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_count_down.scss */
.count-down-area .single-counter span {
  color: #000000;
  font-size: 60px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 1;
  margin-bottom: 12px;
  display: block;
}

/* line 40, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_count_down.scss */
.count-down-area .single-counter p {
  font-size: 18px;
  color: #737980;
  line-height: 1;
  margin: 0;
  padding: 0;
}

/* line 1, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services_section.scss */
.services-area {
  background: #f7f7f7;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services_section.scss */
  .services-area .single-services {
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  /* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services_section.scss */
  .services-area .single-services {
    margin-bottom: 30px;
  }
}

/* line 11, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services_section.scss */
.services-area .single-services .services-img img {
  width: 100%;
}

/* line 15, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services_section.scss */
.services-area .single-services .services-caption {
  position: relative;
  background: #fff;
  width: 80%;
  padding: 33px 46px 16px 32px;
  margin-top: -50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 15, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services_section.scss */
  .services-area .single-services .services-caption {
    width: 95%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 15, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services_section.scss */
  .services-area .single-services .services-caption {
    width: 100%;
    padding: 33px 16px 16px 26px;
    margin-top: 0px;
  }
}

@media (max-width: 575px) {
  /* line 15, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services_section.scss */
  .services-area .single-services .services-caption {
    width: 100%;
    padding: 33px 16px 16px 26px;
    margin-top: 0px;
  }
}

/* line 34, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services_section.scss */
.services-area .single-services .services-caption span {
  margin-bottom: 15px;
  line-height: 13px;
  font-family: "Roboto", sans-serif;
  position: relative;
  color: #e94d65;
  font-weight: 300;
  font-size: 14px;
  display: block;
  padding-left: 60px;
}

/* line 44, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services_section.scss */
.services-area .single-services .services-caption span::before {
  position: absolute;
  content: "";
  width: 35px;
  height: 2px;
  top: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #e94d65;
}

/* line 56, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services_section.scss */
.services-area .single-services .services-caption p {
  margin-bottom: 15px;
  line-height: 1.5;
  font-family: "Poppins", sans-serif;
}

/* line 60, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services_section.scss */
.services-area .single-services .services-caption p a {
  color: #0e132a;
  font-weight: 400;
  font-size: 20px;
  display: block;
}

/* line 65, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services_section.scss */
.services-area .single-services .services-caption p a:hover {
  color: #14435c;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 60, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services_section.scss */
  .services-area .single-services .services-caption p a {
    font-size: 18px;
  }
}

@media (max-width: 575px) {
  /* line 60, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services_section.scss */
  .services-area .single-services .services-caption p a {
    font-size: 18px;
  }
}

/* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_wantTowork.scss */
.wantToWork-area .wantToWork-caption h2 {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_wantTowork.scss */
  .wantToWork-area .wantToWork-caption h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_wantTowork.scss */
  .wantToWork-area .wantToWork-caption h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  /* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_wantTowork.scss */
  .wantToWork-area .wantToWork-caption h2 {
    font-size: 25px;
    margin-bottom: 20px;
  }
}

/* line 22, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_wantTowork.scss */
.wantToWork-area .wantToWork-caption p {
  font-size: 18px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 26, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_wantTowork.scss */
  .wantToWork-area .wantToWork-btn {
    float: left;
    margin-top: 30px;
  }
}

@media (max-width: 575px) {
  /* line 26, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_wantTowork.scss */
  .wantToWork-area .wantToWork-btn {
    float: left;
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 26, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_wantTowork.scss */
  .wantToWork-area .wantToWork-btn {
    float: left;
    margin-top: 30px;
  }
}

/* line 1, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_team.scss */
.team-area {
  background: #f7f7f7;
}

/* line 4, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_team.scss */
.team-area .single-team .team-img {
  overflow: hidden;
}

/* line 6, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_team.scss */
.team-area .single-team .team-img img {
  width: 100%;
  transform: scale(1);
  transition: all 0.6s ease-out 0s;
}

/* line 12, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_team.scss */
.team-area .single-team .team-caption {
  padding: 20px 20px 20px 50px;
  background: #fff;
}

/* line 15, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_team.scss */
.team-area .single-team .team-caption span {
  color: #ff2143;
  font-size: 14px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  display: block;
  position: relative;
  margin-left: 50px;
  margin-bottom: 10px;
}

/* line 24, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_team.scss */
.team-area .single-team .team-caption span::before {
  position: absolute;
  content: "";
  width: 35px;
  height: 2px;
  left: -47px;
  top: 50%;
  transform: translateY(-50%);
  background: #ff2143;
}

/* line 36, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_team.scss */
.team-area .single-team .team-caption h3 a {
  color: #161e46;
  font-weight: 700;
  font-size: 25px;
}

/* line 45, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_team.scss */
.single-team:hover .team-img img {
  transform: scale(1.05);
}

/* line 49, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_team.scss */
.brand-area {
  background: #f7f7f7;
}

/* line 51, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_team.scss */
.brand-area .single-brand.slick-slide {
  opacity: 0.7;
}

/* line 59, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_team.scss */
.about-details .about-details-cap h4 {
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  margin-bottom: 30px;
  color: #000a2d;
  display: inline-block;
  position: relative;
  padding-left: 68px;
}

/* line 69, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_team.scss */
.about-details .about-details-cap h4::before {
  position: absolute;
  content: "";
  width: 54px;
  height: 2px;
  background: #eb566c;
  top: 0;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}

/* line 81, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_team.scss */
.about-details .about-details-cap p {
  color: #464d65;
  font-size: 14px;
}

/* line 1, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
.testimonial-area {
  background: #121212;
  position: relative;
}

/* line 4, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
.testimonial-area .testimonial-caption {
  padding-left: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 4, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
  .testimonial-area .testimonial-caption {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 4, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
  .testimonial-area .testimonial-caption {
    padding-left: 0px;
  }
}

@media (max-width: 575px) {
  /* line 4, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
  .testimonial-area .testimonial-caption {
    padding-left: 0px;
  }
}

/* line 16, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
.testimonial-area .testimonial-caption .testimonial-top-cap svg {
  margin-bottom: 50px;
  width: auto;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 16, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
  .testimonial-area .testimonial-caption .testimonial-top-cap svg {
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  /* line 16, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
  .testimonial-area .testimonial-caption .testimonial-top-cap svg {
    margin-bottom: 30px;
  }
}

/* line 27, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
.testimonial-area .testimonial-caption .testimonial-top-cap p {
  line-height: 1.5;
  padding-right: 100px;
  margin-bottom: 50px;
  font-size: 17px;
  color: #d3d3d4;
  font-weight: 300;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 27, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
  .testimonial-area .testimonial-caption .testimonial-top-cap p {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 27, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
  .testimonial-area .testimonial-caption .testimonial-top-cap p {
    font-size: 20px;
    padding-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 27, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
  .testimonial-area .testimonial-caption .testimonial-top-cap p {
    font-size: 18px;
    padding-right: 0px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 27, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
  .testimonial-area .testimonial-caption .testimonial-top-cap p {
    font-size: 18px;
    padding-right: 0px;
    margin-bottom: 60px;
  }
}

@media (max-width: 575px) {
  /* line 27, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
  .testimonial-area .testimonial-caption .testimonial-top-cap p {
    font-size: 15px;
    padding-right: 0px;
    margin-bottom: 60px;
  }
}

/* line 62, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
.testimonial-area .testimonial-caption .testimonial-founder .founder-text {
  margin-left: 20px;
}

/* line 64, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
.testimonial-area .testimonial-caption .testimonial-founder .founder-text span {
  color: #f04e3c;
  font-size: 30px;
  font-weight: 600;
}

@media (max-width: 575px) {
  /* line 64, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
  .testimonial-area
    .testimonial-caption
    .testimonial-founder
    .founder-text
    span {
    font-size: 26px;
  }
}

/* line 72, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
.testimonial-area .testimonial-caption .testimonial-founder .founder-text p {
  color: #57667e;
  font-weight: 500;
  line-height: 1.5;
}

/* line 81, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
.testimonial-area button.slick-arrow {
  position: absolute;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  padding: 0;
  z-index: 2;
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  color: #616373;
  background: none;
  margin: 0 auto;
  bottom: 0;
  border: 1px solid #616373;
}

/* line 101, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
.testimonial-area button.slick-arrow:hover {
  border: 1px solid #f04e3c;
  color: #f04e3c;
}

/* line 106, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
.testimonial-area button.slick-next {
  left: auto;
  right: -60px;
}

/* line 110, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
.testimonial-area button i {
  font-size: 13px;
  position: relative;
  top: -3px;
}

/* line 116, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_testimonial.scss */
.testimonial-area .slick-initialized .slick-slide {
  outline: 0;
}

/* line 1, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area {
  background: #11141b;
}

/* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top {
  border-bottom: 1px solid #233148;
}

/* line 6, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top .single-footer-caption .footer-logo {
  margin-bottom: 30px;
}

/* line 10, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top .single-footer-caption .footer-pera .info1 {
  line-height: 1.8;
  margin-bottom: 35px;
  color: #bdbdbd;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 10, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
  .footer-area .footer-top .single-footer-caption .footer-pera .info1 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 10, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
  .footer-area .footer-top .single-footer-caption .footer-pera .info1 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 10, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
  .footer-area .footer-top .single-footer-caption .footer-pera .info1 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 10, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
  .footer-area .footer-top .single-footer-caption .footer-pera .info1 {
    padding-right: 0px;
  }
}

/* line 29, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top .single-footer-caption .footer-number h4 {
  color: #ff0000;
  font-size: 30px;
  font-weight: 500;
  text-transform: capitalize;
}

/* line 34, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top .single-footer-caption .footer-number h4 span {
  color: #fff;
}

/* line 38, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top .single-footer-caption .footer-number p {
  color: #bdbdbd;
}

/* line 43, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top .single-footer-caption .footer-tittle h4 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  margin-bottom: 38px;
  font-weight: 500;
  text-transform: uppercase;
}

/* line 52, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top .single-footer-caption .footer-tittle ul li {
  color: #bdbdbd;
  margin-bottom: 15px;
  text-transform: capitalize;
}

/* line 56, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top .single-footer-caption .footer-tittle ul li a {
  color: #bdbdbd;
  font-weight: 300;
}

/* line 59, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top .single-footer-caption .footer-tittle ul li a:hover {
  color: #fff;
  padding-left: 5px;
}

/* line 67, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top .single-footer-caption .footer-form {
  margin-bottom: 30px;
}

/* line 69, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top .single-footer-caption .footer-form form {
  position: relative;
}

/* line 71, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top .single-footer-caption .footer-form form input {
  width: 100%;
  height: 43px;
  padding: 10px 20px;
  border: 0;
  background: #101720;
  color: #fff;
}

/* line 78, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area
  .footer-top
  .single-footer-caption
  .footer-form
  form
  input::placeholder {
  color: #9fa6bd;
  font-weight: 300;
  font-size: 14px;
}

/* line 85, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area
  .footer-top
  .single-footer-caption
  .footer-form
  form
  .form-icon
  button {
  position: absolute;
  top: 0px;
  right: 0;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 14px 22px;
  background: #0a0f17;
  line-height: 1;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}

/* line 102, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top .single-footer-caption .info.error {
  color: #ff0000;
}

/* line 105, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top .single-footer-caption .footer-cap {
  margin-bottom: 30px;
}

/* line 107, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top .single-footer-caption .footer-cap span {
  color: #14435c;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 10px;
  display: block;
}

/* line 114, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-top .single-footer-caption .footer-cap p {
  color: #bdbdbd;
  font-size: 16px;
}

/* line 122, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-bottom {
  padding-bottom: 50px;
  padding-top: 30px;
}

/* line 127, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-bottom .footer-copy-right p {
  color: #bdbdbd;
  font-weight: 300;
  font-size: 16px;
  line-height: 2;
  margin-bottom: 12px;
}

/* line 133, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-bottom .footer-copy-right p i {
  color: #ff0000;
}

/* line 136, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-bottom .footer-copy-right p a {
  color: #ff0000;
}

/* line 150, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-social span {
  color: #fff;
  position: relative;
  margin-right: 57px;
}

/* line 155, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-social span::before {
  position: absolute;
  content: "";
  width: 43px;
  height: 2px;
  background: #3b3647;
  top: 50%;
  transform: translateY(-50%);
  right: -56px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 149, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
  .footer-area .footer-social {
    float: left;
    padding-top: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 149, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
  .footer-area .footer-social {
    float: left;
    padding-top: 10px;
  }
}

@media (max-width: 575px) {
  /* line 149, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
  .footer-area .footer-social {
    float: left;
    padding-top: 10px;
  }
}

/* line 179, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-social a {
  color: #bdbdbd;
  font-size: 14px;
  text-align: center;
  margin-left: 16px;
}

/* line 184, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-social a:first-child {
  margin-left: 0px;
}

/* line 187, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_footer.scss */
.footer-area .footer-social a:hover {
  color: #fff;
}

/* line 2, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services.scss */
.hero-caption span {
  color: #fff;
  font-size: 16px;
  display: block;
  margin-bottom: 24px;
  font-weight: 600;
  padding-left: 95px;
  position: relative;
}

/* line 10, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services.scss */
.hero-caption span::before {
  position: absolute;
  content: "";
  width: 75px;
  height: 3px;
  background: #14435c;
  left: 0;
  top: 52%;
  transform: translateY(-50%);
}

/* line 21, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_services.scss */
.hero-caption h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
}

/* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.home-blog-area {
  background: #f7f7f7;
}

/* line 5, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.home-blog-area .home-blog-single {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

/* line 8, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.home-blog-area .home-blog-single .blog-img-cap .blog-img {
  overflow: hidden;
  position: relative;
}

/* line 11, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.home-blog-area .home-blog-single .blog-img-cap .blog-img img {
  width: 100%;
  -webkit-transform: scale 1;
  -ms-transform: scale 1;
  transform: scale 1;
  transition: all 0.5s ease-out 0s;
}

/* line 16, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.home-blog-area .home-blog-single .blog-img-cap .blog-img ul {
  position: absolute;
  bottom: 0;
  width: 70%;
}

@media (max-width: 575px) {
  /* line 16, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .home-blog-area .home-blog-single .blog-img-cap .blog-img ul {
    width: 85%;
  }
}

/* line 23, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.home-blog-area .home-blog-single .blog-img-cap .blog-img ul li {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #011548;
  padding: 20px 30px 20px 84px;
  background: #f7f7f7;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 23, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .home-blog-area .home-blog-single .blog-img-cap .blog-img ul li {
    padding: 20px 10px 20px 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 23, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .home-blog-area .home-blog-single .blog-img-cap .blog-img ul li {
    padding: 20px 10px 20px 20px;
  }
}

@media (max-width: 575px) {
  /* line 23, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .home-blog-area .home-blog-single .blog-img-cap .blog-img ul li {
    padding: 20px 10px 20px 20px;
  }
}

/* line 43, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.home-blog-area .home-blog-single .blog-img-cap .blog-cap {
  background: #fff;
  padding: 30px 86px 36px 81px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* line 43, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .home-blog-area .home-blog-single .blog-img-cap .blog-cap {
    padding: 37px 23px 37px 45px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 43, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .home-blog-area .home-blog-single .blog-img-cap .blog-cap {
    padding: 30px 8px 31px 26px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 43, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .home-blog-area .home-blog-single .blog-img-cap .blog-cap {
    padding: 30px 8px 31px 26px;
  }
}

@media (max-width: 575px) {
  /* line 43, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .home-blog-area .home-blog-single .blog-img-cap .blog-cap {
    padding: 30px 8px 31px 26px;
  }
}

/* line 59, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.home-blog-area .home-blog-single .blog-img-cap .blog-cap h3 a {
  color: #191d34;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 20px;
  display: block;
}

/* line 66, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.home-blog-area .home-blog-single .blog-img-cap .blog-cap h3 a:hover {
  color: #14435c;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 59, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .home-blog-area .home-blog-single .blog-img-cap .blog-cap h3 a {
    font-size: 23px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 59, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .home-blog-area .home-blog-single .blog-img-cap .blog-cap h3 a {
    font-size: 19px;
  }
}

@media (max-width: 575px) {
  /* line 59, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .home-blog-area .home-blog-single .blog-img-cap .blog-cap h3 a {
    font-size: 19px;
  }
}

/* line 81, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.home-blog-area .home-blog-single .blog-img-cap .blog-cap p {
  color: #737980;
  font-size: 16px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 81, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .home-blog-area .home-blog-single .blog-img-cap .blog-cap p {
    font-size: 14px;
  }
}

/* line 89, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.home-blog-area .home-blog-single .blog-img-cap .blog-cap .more-btn {
  color: #ff2143;
  text-transform: capitalize;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  position: relative;
  padding-left: 50px;
}

/* line 96, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.home-blog-area .home-blog-single .blog-img-cap .blog-cap .more-btn::before {
  position: absolute;
  content: "";
  width: 35px;
  height: 2px;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: #ff2143;
}

/* line 112, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.home-blog-single:hover {
  box-shadow: 0px 22px 57px 0px rgba(34, 41, 72, 0.05);
}

/* line 113, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.home-blog-single:hover .blog-img img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

/* Start Blog Area css
============================================================================================ */
/* line 127, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.latest-blog-area .area-heading {
  margin-bottom: 70px;
}

/* line 132, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_area a {
  color: "Roboto", sans-serif !important;
  text-decoration: none;
  transition: 0.4s;
}

/* line 136, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_area a:hover,
.blog_area a :hover {
  background: -webkit-linear-gradient(131deg, #14435c 0%, #14435c 99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  transition: 0.4s;
}

/* line 146, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-blog {
  overflow: hidden;
  margin-bottom: 30px;
}

/* line 150, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-blog:hover {
  box-shadow: 0px 10px 20px 0px rgba(42, 34, 123, 0.1);
}

/* line 154, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-blog .thumb {
  overflow: hidden;
  position: relative;
}

/* line 158, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-blog .thumb:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/* line 171, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-blog h4 {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 34px;
  margin-bottom: 25px;
}

/* line 178, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-blog a {
  font-size: 20px;
  font-weight: 600;
}

/* line 188, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-blog .date {
  color: #666666;
  text-align: left;
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
}

/* line 196, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-blog .tag {
  text-align: left;
  display: inline-block;
  float: left;
  font-size: 13px;
  font-weight: 300;
  margin-right: 22px;
  position: relative;
}

/* line 206, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-blog .tag:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 10px;
  background: #acacac;
  right: -12px;
  top: 7px;
}

@media (max-width: 1199px) {
  /* line 196, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .single-blog .tag {
    margin-right: 8px;
  }
  /* line 220, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .single-blog .tag:after {
    display: none;
  }
}

/* line 226, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-blog .likes {
  margin-right: 16px;
}

@media (max-width: 800px) {
  /* line 146, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .single-blog {
    margin-bottom: 30px;
  }
}

/* line 234, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-blog .single-blog-content {
  padding: 30px;
}

/* line 238, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-blog .single-blog-content .meta-bottom p {
  font-size: 13px;
  font-weight: 300;
}

/* line 243, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-blog .single-blog-content .meta-bottom i {
  color: #fdcb9e;
  font-size: 13px;
  margin-right: 7px;
}

@media (max-width: 1199px) {
  /* line 234, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .single-blog .single-blog-content {
    padding: 15px;
  }
}

/* line 257, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-blog:hover .thumb:after {
  opacity: 0.7;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (max-width: 1199px) {
  /* line 265, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .single-blog h4 {
    transition: all 300ms linear 0s;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 14px;
    margin-bottom: 12px;
  }
  /* line 271, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .single-blog h4 a {
    font-size: 18px;
  }
}

/* line 279, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.full_image.single-blog {
  position: relative;
}

/* line 282, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.full_image.single-blog .single-blog-content {
  position: absolute;
  left: 35px;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (min-width: 992px) {
  /* line 282, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .full_image.single-blog .single-blog-content {
    bottom: 100px;
  }
}

/* line 301, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.full_image.single-blog h4 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-bottom: none;
  padding-bottom: 5px;
}

/* line 307, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.full_image.single-blog a {
  font-size: 20px;
  font-weight: 600;
}

/* line 317, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.full_image.single-blog .date {
  color: #fff;
}

/* line 322, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.full_image.single-blog:hover .single-blog-content {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/* End Blog Area css
============================================================================================ */
/* Latest Blog Area css
============================================================================================ */
/* line 346, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.l_blog_item .l_blog_text .date {
  margin-top: 24px;
  margin-bottom: 15px;
}

/* line 350, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.l_blog_item .l_blog_text .date a {
  font-size: 12px;
}

/* line 356, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.l_blog_item .l_blog_text h4 {
  font-size: 18px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 0px;
  padding-bottom: 20px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/* line 369, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.l_blog_item .l_blog_text p {
  margin-bottom: 0px;
  padding-top: 20px;
}

/* End Latest Blog Area css
============================================================================================ */
/* Causes Area css
============================================================================================ */
/* line 385, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.causes_slider .owl-dots {
  text-align: center;
  margin-top: 80px;
}

/* line 389, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.causes_slider .owl-dots .owl-dot {
  height: 14px;
  width: 14px;
  background: #eeeeee;
  display: inline-block;
  margin-right: 7px;
}

/* line 396, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.causes_slider .owl-dots .owl-dot:last-child {
  margin-right: 0px;
}

/* line 407, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.causes_item {
  background: #fff;
}

/* line 410, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.causes_item .causes_img {
  position: relative;
}

/* line 413, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.causes_item .causes_img .c_parcent {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  height: 3px;
  background: rgba(255, 255, 255, 0.5);
}

/* line 421, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.causes_item .causes_img .c_parcent span {
  width: 70%;
  height: 3px;
  position: absolute;
  left: 0px;
  bottom: 0px;
}

/* line 429, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.causes_item .causes_img .c_parcent span:before {
  content: "75%";
  position: absolute;
  right: -10px;
  bottom: 0px;
  color: #fff;
  padding: 0px 5px;
}

/* line 442, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.causes_item .causes_text {
  padding: 30px 35px 40px 30px;
}

/* line 445, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.causes_item .causes_text h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  cursor: pointer;
}

/* line 458, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.causes_item .causes_text p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 0px;
}

/* line 468, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.causes_item .causes_bottom a {
  width: 50%;
  border: 1px solid;
  text-align: center;
  float: left;
  line-height: 50px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

/* line 480, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.causes_item .causes_bottom a + a {
  border-color: #eeeeee;
  background: #fff;
  font-size: 14px;
}

/* End Causes Area css
============================================================================================ */
/*================= latest_blog_area css =============*/
/* line 496, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.latest_blog_area {
  background: #f9f9ff;
}

/* line 500, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-recent-blog-post {
  margin-bottom: 30px;
}

/* line 503, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-recent-blog-post .thumb {
  overflow: hidden;
}

/* line 506, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-recent-blog-post .thumb img {
  transition: all 0.7s linear;
}

/* line 511, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-recent-blog-post .details {
  padding-top: 30px;
}

/* line 514, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-recent-blog-post .details .sec_h4 {
  line-height: 24px;
  padding: 10px 0px 13px;
  transition: all 0.3s linear;
}

/* line 525, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-recent-blog-post .date {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

/* line 532, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-recent-blog-post:hover img {
  transform: scale(1.23) rotate(10deg);
}

/* line 539, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.tags .tag_btn {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid #eeeeee;
  display: inline-block;
  padding: 1px 18px;
  text-align: center;
}

/* line 553, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.tags .tag_btn + .tag_btn {
  margin-left: 2px;
}

/*========= blog_categorie_area css ===========*/
/* line 560, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_categorie_area {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 900px) {
  /* line 560, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .blog_categorie_area {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 1100px) {
  /* line 560, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .blog_categorie_area {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

/* line 576, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.categories_post {
  position: relative;
  text-align: center;
  cursor: pointer;
}

/* line 581, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.categories_post img {
  max-width: 100%;
}

/* line 585, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.categories_post .categories_details {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: rgba(34, 34, 34, 0.75);
  color: #fff;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 598, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.categories_post .categories_details h5 {
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: #fff;
  position: relative;
}

/* line 616, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.categories_post .categories_details p {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0px;
}

/* line 623, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.categories_post .categories_details .border_line {
  margin: 10px 0px;
  background: #fff;
  width: 100%;
  height: 1px;
}

/* line 632, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.categories_post:hover .categories_details {
  background: rgba(222, 99, 32, 0.85);
}

/*============ blog_left_sidebar css ==============*/
/* line 647, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_item {
  margin-bottom: 50px;
}

/* line 651, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_details {
  padding: 30px 0 20px 10px;
  box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3);
}

@media (min-width: 768px) {
  /* line 651, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .blog_details {
    padding: 60px 30px 35px 35px;
  }
}

/* line 659, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_details p {
  margin-bottom: 30px;
}

/* line 663, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_details a {
  color: #242b5e;
}

/* line 666, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_details a:hover {
  color: #ff2143;
}

/* line 671, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_details h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  /* line 671, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .blog_details h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
}

/* line 685, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog-info-link li {
  float: left;
  font-size: 14px;
}

/* line 689, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog-info-link li a {
  color: #999999;
}

/* line 693, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog-info-link li i,
.blog-info-link li span {
  font-size: 13px;
  margin-right: 5px;
}

/* line 699, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog-info-link li::after {
  content: "|";
  padding-left: 10px;
  padding-right: 10px;
}

/* line 705, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog-info-link li:last-child::after {
  display: none;
}

/* line 710, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog-info-link::after {
  content: "";
  display: block;
  clear: both;
  display: table;
}

/* line 718, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_item_img {
  position: relative;
}

/* line 721, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_item_img .blog_item_date {
  position: absolute;
  bottom: -10px;
  left: 10px;
  display: block;
  color: #fff;
  background-color: #14435c;
  padding: 8px 15px;
  border-radius: 5px;
}

@media (min-width: 768px) {
  /* line 721, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .blog_item_img .blog_item_date {
    bottom: -20px;
    left: 40px;
    padding: 13px 30px;
  }
}

/* line 737, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_item_img .blog_item_date h3 {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
  line-height: 1.2;
}

@media (min-width: 768px) {
  /* line 737, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .blog_item_img .blog_item_date h3 {
    font-size: 30px;
  }
}

/* line 749, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_item_img .blog_item_date p {
  font-size: 18px;
  margin-bottom: 0;
  color: #fff;
}

@media (min-width: 768px) {
  /* line 749, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .blog_item_img .blog_item_date p {
    font-size: 18px;
  }
}

/* line 769, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .widget_title {
  font-size: 20px;
  margin-bottom: 40px;
}

/* line 774, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .widget_title::after {
  content: "";
  display: block;
  padding-top: 15px;
  border-bottom: 1px solid #f0e9ff;
}

/* line 782, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .single_sidebar_widget {
  background: #fbf9ff;
  padding: 30px;
  margin-bottom: 30px;
}

/* line 786, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .single_sidebar_widget .btn_1 {
  margin-top: 0px;
}

/* line 794, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .search_widget .form-control {
  height: 50px;
  border-color: #f0e9ff;
  font-size: 13px;
  color: #999999;
  padding-left: 20px;
  border-radius: 0;
  border-right: 0;
}

/* line 803, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .search_widget .form-control::placeholder {
  color: #999999;
}

/* line 807, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .search_widget .form-control:focus {
  border-color: #f0e9ff;
  outline: 0;
  box-shadow: none;
}

/* line 816, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .search_widget .input-group button {
  background: #14435c;
  border-left: 0;
  border: 1px solid #f0e9ff;
  padding: 4px 15px;
  border-left: 0;
  cursor: pointer;
}

/* line 824, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .search_widget .input-group button i {
  color: #fff;
}

/* line 827, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .search_widget .input-group button span {
  font-size: 14px;
  color: #999999;
}

/* line 838, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .newsletter_widget .form-control {
  height: 50px;
  border-color: #f0e9ff;
  font-size: 13px;
  color: #999999;
  padding-left: 20px;
  border-radius: 0;
}

/* line 847, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .newsletter_widget .form-control::placeholder {
  color: #999999;
}

/* line 851, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .newsletter_widget .form-control:focus {
  border-color: #f0e9ff;
  outline: 0;
  box-shadow: none;
}

/* line 860, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .newsletter_widget .input-group button {
  background: #fff;
  border-left: 0;
  border: 1px solid #f0e9ff;
  padding: 4px 15px;
  border-left: 0;
}

/* line 867, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .newsletter_widget .input-group button i,
.blog_right_sidebar .newsletter_widget .input-group button span {
  font-size: 14px;
  color: #fff;
}

/* line 880, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .post_category_widget .cat-list li {
  border-bottom: 1px solid #f0e9ff;
  transition: all 0.3s ease 0s;
  padding-bottom: 12px;
}

/* line 885, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .post_category_widget .cat-list li:last-child {
  border-bottom: 0;
}

/* line 889, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .post_category_widget .cat-list li a {
  font-size: 14px;
  line-height: 20px;
  color: #888888;
}

/* line 894, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .post_category_widget .cat-list li a p {
  margin-bottom: 0px;
}

/* line 899, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .post_category_widget .cat-list li + li {
  padding-top: 15px;
}

/* line 916, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body {
  justify-content: center;
  align-self: center;
  padding-left: 20px;
}

/* line 921, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 6px;
  transition: all 0.3s linear;
}

/* line 932, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body a:hover {
  color: #fff;
}

/* line 938, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body p {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0px;
}

/* line 945, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .popular_post_widget .post_item + .post_item {
  margin-top: 20px;
}

/* line 953, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .tag_cloud_widget ul li {
  display: inline-block;
}

/* line 956, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .tag_cloud_widget ul li a {
  display: inline-block;
  border: 1px solid #eeeeee;
  background: #fff;
  padding: 4px 20px;
  margin-bottom: 8px;
  margin-right: 3px;
  transition: all 0.3s ease 0s;
  color: #888888;
  font-size: 13px;
}

/* line 967, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .tag_cloud_widget ul li a:hover {
  background: #14435c;
  color: #fff !important;
  -webkit-text-fill-color: #fff;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 982, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .instagram_feeds .instagram_row {
  display: flex;
  margin-right: -6px;
  margin-left: -6px;
}

/* line 988, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .instagram_feeds .instagram_row li {
  width: 33.33%;
  float: left;
  padding-right: 6px;
  padding-left: 6px;
  margin-bottom: 15px;
}

/* line 1088, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog_right_sidebar .br {
  width: 100%;
  height: 1px;
  background: #eeeeee;
  margin: 30px 0px;
}

/* line 1109, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog-pagination {
  margin-top: 80px;
}

/* line 1113, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog-pagination .page-link {
  font-size: 14px;
  position: relative;
  display: block;
  padding: 0;
  text-align: center;
  margin-left: -1px;
  line-height: 45px;
  width: 45px;
  height: 45px;
  border-radius: 0 !important;
  color: #8a8a8a;
  border: 1px solid #f0e9ff;
  margin-right: 10px;
}

/* line 1130, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog-pagination .page-link i,
.blog-pagination .page-link span {
  font-size: 13px;
}

/* line 1142, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog-pagination .page-item.active .page-link {
  background-color: #fbf9ff;
  border-color: #f0e9ff;
  color: #888888;
}

/* line 1149, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.blog-pagination .page-item:last-child .page-link {
  margin-right: 0;
}

/*============ Start Blog Single Styles  =============*/
/* line 1174, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .blog_details {
  box-shadow: none;
  padding: 0;
}

/* line 1179, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .social-links {
  padding-top: 10px;
}

/* line 1182, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .social-links li {
  display: inline-block;
  margin-bottom: 10px;
}

/* line 1186, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .social-links li a {
  color: #cccccc;
  padding: 7px;
  font-size: 14px;
  transition: all 0.2s linear;
}

/* line 1199, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .blog_details {
  padding-top: 26px;
}

/* line 1202, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .blog_details p {
  margin-bottom: 20px;
  font-size: 15px;
}

/* line 1212, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .quote-wrapper {
  background: rgba(130, 139, 178, 0.1);
  padding: 15px;
  line-height: 1.733;
  color: #888888;
  font-style: italic;
  margin-top: 25px;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  /* line 1212, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .single-post-area .quote-wrapper {
    padding: 30px;
  }
}

/* line 1226, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .quotes {
  background: #fff;
  padding: 15px 15px 15px 20px;
  border-left: 2px solid;
}

@media (min-width: 768px) {
  /* line 1226, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .single-post-area .quotes {
    padding: 25px 25px 25px 30px;
  }
}

/* line 1236, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .arrow {
  position: absolute;
}

/* line 1239, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .arrow .lnr {
  font-size: 20px;
  font-weight: 600;
}

/* line 1246, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .thumb .overlay-bg {
  background: rgba(0, 0, 0, 0.8);
}

/* line 1251, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-top {
  padding-top: 15px;
  border-top: 1px solid #f0e9ff;
}

/* line 1255, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-top p {
  margin-bottom: 0;
}

/* line 1259, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-top .like-info {
  font-size: 14px;
}

/* line 1262, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-top .like-info i,
.single-post-area .navigation-top .like-info span {
  font-size: 16px;
  margin-right: 5px;
}

/* line 1269, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-top .comment-count {
  font-size: 14px;
}

/* line 1272, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-top .comment-count i,
.single-post-area .navigation-top .comment-count span {
  font-size: 16px;
  margin-right: 5px;
}

/* line 1281, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-top .social-icons li {
  display: inline-block;
  margin-right: 15px;
}

/* line 1285, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-top .social-icons li:last-child {
  margin: 0;
}

/* line 1289, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-top .social-icons li i,
.single-post-area .navigation-top .social-icons li span {
  font-size: 14px;
  color: #999999;
}

/* line 1307, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .blog-author {
  padding: 40px 30px;
  background: #fbf9ff;
  margin-top: 50px;
}

@media (max-width: 600px) {
  /* line 1307, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .single-post-area .blog-author {
    padding: 20px 8px;
  }
}

/* line 1316, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .blog-author img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-right: 30px;
}

@media (max-width: 600px) {
  /* line 1316, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .single-post-area .blog-author img {
    margin-right: 15px;
    width: 45px;
    height: 45px;
  }
}

/* line 1329, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .blog-author a {
  display: inline-block;
}

/* line 1333, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .blog-author a:hover {
  color: #ff2143;
}

/* line 1338, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .blog-author p {
  margin-bottom: 0;
  font-size: 15px;
}

/* line 1343, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .blog-author h4 {
  font-size: 16px;
}

/* line 1350, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-area {
  border-bottom: 1px solid #eee;
  padding-bottom: 30px;
  margin-top: 55px;
}

/* line 1355, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-area p {
  margin-bottom: 0px;
}

/* line 1359, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-area h4 {
  font-size: 18px;
  line-height: 25px;
}

/* line 1365, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-area .nav-left {
  text-align: left;
}

/* line 1368, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-area .nav-left .thumb {
  margin-right: 20px;
  background: #000;
}

/* line 1372, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-area .nav-left .thumb img {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/* line 1377, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-area .nav-left .lnr {
  margin-left: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/* line 1384, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-area .nav-left:hover .lnr {
  opacity: 1;
}

/* line 1389, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-area .nav-left:hover .thumb img {
  opacity: 0.5;
}

@media (max-width: 767px) {
  /* line 1365, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .single-post-area .navigation-area .nav-left {
    margin-bottom: 30px;
  }
}

/* line 1400, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-area .nav-right {
  text-align: right;
}

/* line 1403, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-area .nav-right .thumb {
  margin-left: 20px;
  background: #000;
}

/* line 1407, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-area .nav-right .thumb img {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/* line 1412, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-area .nav-right .lnr {
  margin-right: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/* line 1419, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-area .nav-right:hover .lnr {
  opacity: 1;
}

/* line 1424, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.single-post-area .navigation-area .nav-right:hover .thumb img {
  opacity: 0.5;
}

@media (max-width: 991px) {
  /* line 1432, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .single-post-area .sidebar-widgets {
    padding-bottom: 0px;
  }
}

/* line 1439, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comments-area {
  background: transparent;
  border-top: 1px solid #eee;
  padding: 45px 0;
  margin-top: 50px;
}

@media (max-width: 414px) {
  /* line 1439, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .comments-area {
    padding: 50px 8px;
  }
}

/* line 1450, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comments-area h4 {
  margin-bottom: 35px;
  font-size: 18px;
}

/* line 1457, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comments-area h5 {
  font-size: 16px;
  margin-bottom: 0px;
}

/* line 1466, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comments-area .comment-list {
  padding-bottom: 48px;
}

/* line 1469, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comments-area .comment-list:last-child {
  padding-bottom: 0px;
}

/* line 1473, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comments-area .comment-list.left-padding {
  padding-left: 25px;
}

@media (max-width: 413px) {
  /* line 1479, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .comments-area .comment-list .single-comment h5 {
    font-size: 12px;
  }
  /* line 1483, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .comments-area .comment-list .single-comment .date {
    font-size: 11px;
  }
  /* line 1487, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .comments-area .comment-list .single-comment .comment {
    font-size: 10px;
  }
}

/* line 1494, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comments-area .thumb {
  margin-right: 20px;
}

/* line 1497, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comments-area .thumb img {
  width: 70px;
  border-radius: 50%;
}

/* line 1503, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comments-area .date {
  font-size: 14px;
  color: #999999;
  margin-bottom: 0;
  margin-left: 20px;
}

/* line 1510, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comments-area .comment {
  margin-bottom: 10px;
  color: #777777;
  font-size: 15px;
}

/* line 1516, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comments-area .btn-reply {
  background-color: transparent;
  color: #888888;
  padding: 5px 18px;
  font-size: 14px;
  display: block;
  font-weight: 400;
}

/* line 1533, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comment-form {
  border-top: 1px solid #eee;
  padding-top: 45px;
  margin-top: 50px;
  margin-bottom: 20px;
}

/* line 1541, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comment-form .form-group {
  margin-bottom: 30px;
}

/* line 1545, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comment-form h4 {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 22px;
}

/* line 1553, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comment-form .name {
  padding-left: 0px;
}

@media (max-width: 767px) {
  /* line 1553, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .comment-form .name {
    padding-right: 0px;
    margin-bottom: 1rem;
  }
}

/* line 1562, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comment-form .email {
  padding-right: 0px;
}

@media (max-width: 991px) {
  /* line 1562, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
  .comment-form .email {
    padding-left: 0px;
  }
}

/* line 1570, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comment-form .form-control {
  border: 1px solid #f0e9ff;
  border-radius: 5px;
  height: 48px;
  padding-left: 18px;
  font-size: 13px;
  background: transparent;
}

/* line 1578, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comment-form .form-control:focus {
  outline: 0;
  box-shadow: none;
}

/* line 1583, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comment-form .form-control::placeholder {
  font-weight: 300;
  color: #999999;
}

/* line 1588, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comment-form .form-control::placeholder {
  color: #777777;
}

/* line 1593, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comment-form textarea {
  padding-top: 18px;
  border-radius: 12px;
  height: 100% !important;
}

/* line 1599, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comment-form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
  color: #777;
}

/* line 1605, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comment-form ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
  color: #777;
}

/* line 1611, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comment-form :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
  color: #777;
}

/* line 1617, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_blog_page.scss */
.comment-form :-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
  color: #777;
}

/*============ End Blog Single Styles  =============*/
/*=================== contact banner start ====================*/
/* line 3, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 10, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.contact-info {
  margin-bottom: 25px;
}

/* line 13, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.contact-info__icon {
  margin-right: 20px;
}

/* line 16, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.contact-info__icon i,
.contact-info__icon span {
  color: #8f9195;
  font-size: 27px;
}

/* line 24, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.contact-info .media-body h3 {
  font-size: 16px;
  margin-bottom: 0;
  font-size: 16px;
  color: #2a2a2a;
}

/* line 30, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.contact-info .media-body h3 a:hover {
  color: #1f2b7b;
}

/* line 36, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.contact-info .media-body p {
  color: #8a8a8a;
}

/*=================== contact banner end ====================*/
/*=================== contact form start ====================*/
/* line 45, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.contact-title {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 20px;
}

/* line 53, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.form-contact label {
  font-size: 14px;
}

/* line 57, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.form-contact .form-group {
  margin-bottom: 30px;
}

/* line 61, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.form-contact .form-control {
  border: 1px solid #e5e6e9;
  border-radius: 0px;
  height: 48px;
  padding-left: 18px;
  font-size: 13px;
  background: transparent;
}

/* line 69, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.form-contact .form-control:focus {
  outline: 0;
  box-shadow: none;
}

/* line 74, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.form-contact .form-control::placeholder {
  font-weight: 300;
  color: #999999;
}

/* line 80, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.form-contact textarea {
  border-radius: 0px;
  height: 100% !important;
}

/*=================== contact form end ====================*/
/* Contact Success and error Area css
============================================================================================ */
/* line 97, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.modal-message .modal-dialog {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) !important;
  margin: 0px;
  max-width: 500px;
  width: 100%;
}

/* line 106, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header {
  text-align: center;
  display: block;
  border-bottom: none;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* line 112, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  right: -15px;
  top: -15px;
  padding: 0px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
}

/* line 121, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header h2 {
  display: block;
  text-align: center;
  padding-bottom: 10px;
}

/* line 126, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header p {
  display: block;
}

/* line 133, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.contact-section {
  padding: 130px 0 100px;
}

@media (max-width: 991px) {
  /* line 133, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
  .contact-section {
    padding: 70px 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 133, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
  .contact-section {
    padding: 80px 0 50px;
  }
}

/* line 141, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.contact-section .btn_2 {
  background-color: #191d34;
  padding: 18px 60px;
  border-radius: 50px;
  margin-top: 0;
}

/* line 146, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_contact.scss */
.contact-section .btn_2:hover {
  background-color: #1f2b7b;
}

/* line 1, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
.breadcam_bg {
  /* background-image: url(../img/banner/bradcam.png); */
}

/* line 4, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
.breadcam_bg_1 {
  /* background-image: url(../img/banner/bradcam2.png); */
}

/* line 7, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
.breadcam_bg_2 {
  /* background-image: url(../img/banner/bradcam3.png); */
}

/* line 10, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
.bradcam_area {
  background-size: cover;
  background-position: center center;
  padding: 160px 0;
  background-position: bottom;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  /* line 10, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
  .bradcam_area {
    padding: 150px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 10, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
  .bradcam_area {
    padding: 150px 0;
  }
}

/* line 22, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
.bradcam_area h3 {
  font-size: 50px;
  color: #fff;
  font-weight: 900;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}

@media (max-width: 767px) {
  /* line 22, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
  .bradcam_area h3 {
    font-size: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 22, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
  .bradcam_area h3 {
    font-size: 40px;
  }
}

/* line 38, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
.popup_box {
  background: #fff;
  display: inline-block;
  z-index: 9;
  width: 681px;
  padding: 60px 40px;
}

@media (max-width: 767px) {
  /* line 38, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
  .popup_box {
    width: 320px;
    padding: 45px 30px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  /* line 38, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
  .popup_box {
    width: 420px !important;
    padding: 45px 30px;
  }
}

/* line 57, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
.popup_box h3 {
  text-align: center;
  font-size: 22px;
  color: #1f1f1f;
  margin-bottom: 46px;
}

/* line 63, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
.popup_box .boxed-btn3 {
  width: 100%;
  text-transform: capitalize;
}

/* line 67, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
.popup_box .nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  /* border-radius: 5px; */
  border: solid 1px #e2e2e2;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  width: 100% !important;
  /* height: 42px; */
  line-height: 50px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
  border-radius: 0;
  margin-bottom: 30px;
  height: 50px !important;
  font-size: 16px;
  font-weight: 400;
  color: #919191;
}

/* line 101, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
.popup_box .nice-select::after {
  content: "\f0d7";
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 17px;
  top: 3px;
  transition: all 0.15s ease-in-out;
  width: 5px;
  font-family: fontawesome;
  color: #919191;
  font-size: 15px;
}

/* line 116, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
.popup_box .nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  height: 200px;
  overflow-y: scroll;
}

/* line 125, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
.popup_box .nice-select.list {
  height: 200px;
  overflow-y: scroll;
}

/* line 131, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
#test-form {
  display: inline-block;
  margin: auto;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  /* line 131, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
  #test-form {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

/* line 146, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
#test-form .mfp-close-btn-in .mfp-close {
  color: #333;
  display: none !important;
}

/* line 151, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
#test-form button.mfp-close {
  display: none !important;
}

@media (max-width: 767px) {
  /* line 151, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
  #test-form button.mfp-close {
    display: block !important;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
}

/* line 162, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
#test-form button.mfp-close {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
  width: 40px;
  height: 40px;
  background: #4a3600;
  text-align: center;
  line-height: 20px;
  position: absolute;
  right: 0;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  position: absolute;
  right: -6px;
  color: #fff !important;
}

/* line 189, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #4a3600;
  opacity: 0.6;
}

@media (max-width: 767px) {
  /* line 208, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
  .gj-picker.gj-picker-md.timepicker {
    width: 310px;
    left: 6px !important;
  }
}

@media (max-width: 767px) {
  /* line 215, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_bradcam.scss */
  .gj-picker.gj-picker-md.datepicker.gj-unselectable {
    width: 320px;
    left: 0 !important;
  }
}

/* line 1, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_extend.scss */
.flex-center-start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

/* line 13, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_extend.scss */
.overlay::before {
  background-image: -moz-linear-gradient(
    170deg,
    rgba(34, 34, 34, 0) 0%,
    black 100%
  );
  background-image: -webkit-linear-gradient(
    170deg,
    rgba(34, 34, 34, 0) 0%,
    black 100%
  );
  background-image: -ms-linear-gradient(
    170deg,
    rgba(34, 34, 34, 0) 0%,
    black 100%
  );
}

/* line 76, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.sample-text-area {
  background: #fff;
  padding: 100px 0 70px 0;
}

/* line 81, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.text-heading {
  margin-bottom: 30px;
  font-size: 24px;
}

/* line 86, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
b,
sup,
sub,
u,
del {
  color: #1f2b7b;
}

/* line 95, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
  color: #828bb2;
}

/* line 105, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.button-area {
  background: #fff;
}

/* line 106, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.button-area .border-top-generic {
  padding: 70px 15px;
  border-top: 1px dotted #eee;
}

/* line 114, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.button-group-area .genric-btn {
  margin-right: 10px;
  margin-top: 10px;
}

/* line 117, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.button-group-area .genric-btn:last-child {
  margin-right: 0;
}

/* line 123, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn {
  display: inline-block;
  outline: none;
  line-height: 40px;
  padding: 0 30px;
  font-size: 0.8em;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

/* line 134, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn:focus {
  outline: none;
}

/* line 137, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.e-large {
  padding: 0 40px;
  line-height: 50px;
}

/* line 141, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.large {
  line-height: 45px;
}

/* line 144, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.medium {
  line-height: 30px;
}

/* line 147, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.small {
  line-height: 25px;
}

/* line 150, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.radius {
  border-radius: 3px;
}

/* line 153, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.circle {
  border-radius: 20px;
}

/* line 156, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.arrow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 163, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.arrow span {
  margin-left: 10px;
}

/* line 167, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.default {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

/* line 171, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.default:hover {
  border: 1px solid #f9f9ff;
  background: #fff;
}

/* line 176, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.default-border {
  border: 1px solid #f9f9ff;
  background: #fff;
}

/* line 179, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.default-border:hover {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

/* line 185, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.primary {
  color: #fff;
  background: #1f2b7b;
  border: 1px solid transparent;
}

/* line 189, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.primary:hover {
  color: #1f2b7b;
  border: 1px solid #1f2b7b;
  background: #fff;
}

/* line 195, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.primary-border {
  color: #1f2b7b;
  border: 1px solid #1f2b7b;
  background: #fff;
}

/* line 199, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.primary-border:hover {
  color: #fff;
  background: #1f2b7b;
  border: 1px solid transparent;
}

/* line 205, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.success {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

/* line 209, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.success:hover {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

/* line 215, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.success-border {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

/* line 219, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.success-border:hover {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

/* line 225, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.info {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

/* line 229, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.info:hover {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

/* line 235, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.info-border {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

/* line 239, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.info-border:hover {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

/* line 245, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.warning {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

/* line 249, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.warning:hover {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

/* line 255, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.warning-border {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

/* line 259, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.warning-border:hover {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

/* line 265, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.danger {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

/* line 269, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.danger:hover {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

/* line 275, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.danger-border {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

/* line 279, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.danger-border:hover {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

/* line 285, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.link {
  color: #415094;
  background: #f9f9ff;
  text-decoration: underline;
  border: 1px solid transparent;
}

/* line 290, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.link:hover {
  color: #415094;
  border: 1px solid #f9f9ff;
  background: #fff;
}

/* line 296, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.link-border {
  color: #415094;
  border: 1px solid #f9f9ff;
  background: #fff;
  text-decoration: underline;
}

/* line 301, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.link-border:hover {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

/* line 307, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.genric-btn.disable {
  color: #222222, 0.3;
  background: #f9f9ff;
  border: 1px solid transparent;
  cursor: not-allowed;
}

/* line 315, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.generic-blockquote {
  padding: 30px 50px 30px 30px;
  background: #f9f9ff;
  border-left: 2px solid #1f2b7b;
}

/* line 321, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table-wrap {
  overflow-x: scroll;
}

/* line 325, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table {
  background: #f9f9ff;
  padding: 15px 0px 30px 0px;
  min-width: 800px;
}

/* line 329, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .serial {
  width: 11.83%;
  padding-left: 30px;
}

/* line 333, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .country {
  width: 28.07%;
}

/* line 336, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .visit {
  width: 19.74%;
}

/* line 339, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .percentage {
  width: 40.36%;
  padding-right: 50px;
}

/* line 343, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .table-head {
  display: flex;
}

/* line 345, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .table-head .serial,
.progress-table .table-head .country,
.progress-table .table-head .visit,
.progress-table .table-head .percentage {
  color: #415094;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 500;
}

/* line 355, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .table-row {
  padding: 15px 0;
  border-top: 1px solid #edf3fd;
  display: flex;
}

/* line 359, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .table-row .serial,
.progress-table .table-row .country,
.progress-table .table-row .visit,
.progress-table .table-row .percentage {
  display: flex;
  align-items: center;
}

/* line 367, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .table-row .country img {
  margin-right: 15px;
}

/* line 372, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .table-row .percentage .progress {
  width: 80%;
  border-radius: 0px;
  background: transparent;
}

/* line 376, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar {
  height: 5px;
  line-height: 5px;
}

/* line 379, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-1 {
  background-color: #6382e6;
}

/* line 382, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-2 {
  background-color: #e66686;
}

/* line 385, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-3 {
  background-color: #f09359;
}

/* line 388, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-4 {
  background-color: #73fbaf;
}

/* line 391, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-5 {
  background-color: #73fbaf;
}

/* line 394, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-6 {
  background-color: #6382e6;
}

/* line 397, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-7 {
  background-color: #a367e7;
}

/* line 400, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-8 {
  background-color: #e66686;
}

/* line 409, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.single-gallery-image {
  margin-top: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: 200px;
}

/* line 417, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.list-style {
  width: 14px;
  height: 14px;
}

/* line 423, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.unordered-list li {
  position: relative;
  padding-left: 30px;
  line-height: 1.82em !important;
}

/* line 427, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.unordered-list li:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border: 3px solid #1f2b7b;
  background: #fff;
  top: 4px;
  left: 0;
  border-radius: 50%;
}

/* line 441, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.ordered-list {
  margin-left: 30px;
}

/* line 443, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.ordered-list li {
  list-style-type: decimal-leading-zero;
  color: #1f2b7b;
  font-weight: 500;
  line-height: 1.82em !important;
}

/* line 448, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.ordered-list li span {
  font-weight: 300;
  color: #828bb2;
}

/* line 456, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.ordered-list-alpha li {
  margin-left: 30px;
  list-style-type: lower-alpha;
  color: #1f2b7b;
  font-weight: 500;
  line-height: 1.82em !important;
}

/* line 462, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.ordered-list-alpha li span {
  font-weight: 300;
  color: #828bb2;
}

/* line 470, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.ordered-list-roman li {
  margin-left: 30px;
  list-style-type: lower-roman;
  color: #1f2b7b;
  font-weight: 500;
  line-height: 1.82em !important;
}

/* line 476, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.ordered-list-roman li span {
  font-weight: 300;
  color: #828bb2;
}

/* line 483, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 491, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.single-input:focus {
  outline: none;
}

/* line 496, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.input-group-icon {
  position: relative;
}

/* line 498, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.input-group-icon .icon {
  position: absolute;
  left: 20px;
  top: 0;
  line-height: 40px;
  z-index: 3;
}

/* line 503, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.input-group-icon .icon i {
  color: #797979;
}

/* line 508, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.input-group-icon .single-input {
  padding-left: 45px;
}

/* line 513, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.single-textarea {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
  height: 100px;
  resize: none;
}

/* line 523, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.single-textarea:focus {
  outline: none;
}

/* line 528, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.single-input-primary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 536, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.single-input-primary:focus {
  outline: none;
  border: 1px solid #1f2b7b;
}

/* line 542, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.single-input-accent {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 550, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.single-input-accent:focus {
  outline: none;
  border: 1px solid #eb6b55;
}

/* line 556, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.single-input-secondary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 564, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.single-input-secondary:focus {
  outline: none;
  border: 1px solid #f09359;
}

/* line 570, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.default-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 577, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.default-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/* line 587, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.default-switch input + label {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #1f2b7b;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* line 600, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.default-switch input:checked + label {
  left: 19px;
}

/* line 607, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 614, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.primary-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 623, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.primary-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* line 631, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.primary-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

/* line 645, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.primary-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* line 661, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.primary-switch input:checked + label:after {
  left: 19px;
}

/* line 664, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.primary-switch input:checked + label:before {
  background: #1f2b7b;
}

/* line 672, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.confirm-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 679, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.confirm-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 688, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.confirm-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* line 696, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.confirm-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

/* line 710, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.confirm-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* line 726, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.confirm-switch input:checked + label:after {
  left: 19px;
}

/* line 729, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.confirm-switch input:checked + label:before {
  background: #4cd3e3;
}

/* line 737, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.primary-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 744, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.primary-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 753, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.primary-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 766, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.primary-checkbox input:checked + label {
  background: url(../img/elements/primary-check.png) no-repeat center
    center/cover;
  border: none;
}

/* line 774, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.confirm-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 781, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.confirm-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 790, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.confirm-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 803, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.confirm-checkbox input:checked + label {
  background: url(../img/elements/success-check.png) no-repeat center
    center/cover;
  border: none;
}

/* line 811, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.disabled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 818, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.disabled-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 827, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.disabled-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 839, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.disabled-checkbox input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

/* line 844, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.disabled-checkbox input:checked + label {
  background: url(../img/elements/disabled-check.png) no-repeat center
    center/cover;
  border: none;
}

/* line 852, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.primary-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 859, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.primary-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 868, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.primary-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 881, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.primary-radio input:checked + label {
  background: url(../img/elements/primary-radio.png) no-repeat center
    center/cover;
  border: none;
}

/* line 889, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.confirm-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 896, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.confirm-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 905, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.confirm-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 918, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.confirm-radio input:checked + label {
  background: url(../img/elements/success-radio.png) no-repeat center
    center/cover;
  border: none;
}

/* line 926, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.disabled-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 933, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.disabled-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 942, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.disabled-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 954, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.disabled-radio input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

/* line 959, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.disabled-radio input:checked + label {
  background: url(../img/elements/disabled-radio.png) no-repeat center
    center/cover;
  border: none;
}

/* line 967, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.default-select {
  height: 40px;
}

/* line 969, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.default-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 20px;
  padding-right: 40px;
}

/* line 976, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.default-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

/* line 983, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.default-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 20px;
}

/* line 990, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.default-select .nice-select .list .option.selected {
  color: #1f2b7b;
  background: transparent;
}

/* line 994, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.default-select .nice-select .list .option:hover {
  color: #1f2b7b;
  background: transparent;
}

/* line 1001, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.default-select .current {
  margin-right: 50px;
  font-weight: 300;
}

/* line 1005, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.default-select .nice-select::after {
  right: 20px;
}

/* line 1010, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.form-select {
  height: 40px;
  width: 100%;
}

/* line 1013, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.form-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 45px;
  padding-right: 40px;
  width: 100%;
}

/* line 1021, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.form-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

/* line 1028, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.form-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 45px;
}

/* line 1035, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.form-select .nice-select .list .option.selected {
  color: #1f2b7b;
  background: transparent;
}

/* line 1039, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.form-select .nice-select .list .option:hover {
  color: #1f2b7b;
  background: transparent;
}

/* line 1046, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.form-select .current {
  margin-right: 50px;
  font-weight: 300;
}

/* line 1050, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.form-select .nice-select::after {
  right: 20px;
}

/* line 1054, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.mt-10 {
  margin-top: 10px;
}

/* line 1057, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.section-top-border {
  padding: 50px 0;
  border-top: 1px dotted #eee;
}

/* line 1061, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.mb-30 {
  margin-bottom: 30px;
}

/* line 1064, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.mt-30 {
  margin-top: 30px;
}

/* line 1067, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_elements.scss */
.switch-wrap {
  margin-bottom: 10px;
}

/*# sourceMappingURL=style.css.map */
