.gold-bg {
  background-color: #ffd700;
}

.text-gold {
  color: #ffd700;
}

.bg-test {
  background-color: #15435b;
}
